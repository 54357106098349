import './App.css';
import { useState } from "react";
import { useAccount } from 'wagmi'
import { Web3Button } from "@web3modal/react";
import { useContractRead } from 'wagmi';
import { Link } from "react-router-dom";
import { ethers } from "ethers";

// contract abis
import ERC20Abi from "../assets/ERC20Abi.json";
import MariposaAbi from "../assets/MariposaAbi.json";

// contracts addresses :
const WETHContract = process.env.REACT_APP_WETH_CONTRACT;
const MariposaContract = process.env.REACT_APP_MARIPOSA_CONTRACT;

function Dashboard() {
  const { address, isConnected } = useAccount();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const [projectInfo, setProjectInfo] = useState(false);
  const [symbol, setSymbol] = useState('WETH');
  const [mintPriceStr, setMintPriceStr] = useState('?.???');

  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'hasRole',
    args: [process.env.REACT_APP_NFT_ROLE, address],
    onSuccess(data) {
      console.log('IsAdmin : ', data);
      setIsAdmin(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })
  
  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'hasRole',
    args: [process.env.REACT_APP_BANK_ROLE, address],
    onSuccess(data) {
      console.log('IsBank : ', data);
      setIsBank(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })
  
  useContractRead({
    address: WETHContract,
    abi: ERC20Abi,
    functionName: 'symbol',
    onSuccess(data) {
      setSymbol(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })

  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'MRPSProject',
    onSuccess(data) {
      console.log(data);
      setMintPriceStr(ethers.utils.formatEther(data.mintPrice));
      setProjectInfo(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })
  
  return(
    <>
      <div className="ConnBtnDiv-connected"><Web3Button /></div>
      <div className="Dashboard-title">Statut de la collection</div>
      { projectInfo !== false && 
          <div className="Dashboard">
              <div>Phase actuelle :&nbsp;
                  { projectInfo.step === 0 && "Mint non ouvert" }
                  { projectInfo.step === 1 && "Mint des whitelists" }
                  { projectInfo.step === 2 && "Mint public" }
                  { projectInfo.step === 3 && "Sold out" }
              </div>
              <div>NFT révélés : { projectInfo.revealed ? "Oui" : "Pas encore" }</div>
              <div>Minté(s) : { (projectInfo.nextMint-1)+'/'+projectInfo.collectionSize }</div>
              <div>Prix du mint : {mintPriceStr} {symbol}</div>
          </div>
      }
      { isConnected &&
        (isAdmin || isBank) &&
        <div className="Dashboard-admin">
          <div className="Dashboard-title">Administration</div>
          <Link to="/admin">Zone réservée</Link>
        </div>
      }
    </>
  );
}

export default Dashboard;
