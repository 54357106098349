import './App.css';
import { useState } from "react";
import { useAccount } from 'wagmi';
import { Web3Button } from "@web3modal/react";
import { useContractRead, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { ethers } from "ethers";

import WLProofs from "../assets/proofs.json";

// contract abis
import ERC20Abi from "../assets/ERC20Abi.json";
import MariposaAbi from "../assets/MariposaAbi.json";

// contracts addresses :
const WETHContract = process.env.REACT_APP_WETH_CONTRACT;
const MariposaContract = process.env.REACT_APP_MARIPOSA_CONTRACT;

// getProof for address
function getWLProof (address) {
  let proof = [];

  WLProofs.map((entry) => {
    if (address === entry.address[0]) {
      proof = entry.proof;
    }
  });
//  console.log("Adresse : ", address);
//  console.log("Preuve : ", proof);
  return proof;
}

// Step 0 : Non connecté --> Connect
//      1 : Connecté  --> CheckBalance
//      2 : Balance suffisante --> AllowSpend
//      3 : Allowance suffisante --> MINT
//      4 : NFT Minté --> Success
let step = 0;

function FWETHBalance(props) {
  const { address, isConnected } = useAccount();
  const [balance, setBalance] = useState('');
  const [allowance, setAllowance] = useState('');
  const [mintPrice, setMintPrice] = useState('');
  const [mintPriceMB, setMintPriceMB] = useState();
  const [projectStep, setProjectStep] = useState('');
  const [hasMinted, setHasMinted] = useState(false);
  const [projectInfo, setProjectInfo] = useState({loading: true});

  let symbol = 'WETH';
  let lowBalance = false;

  useContractRead({
    address: WETHContract,
    abi: ERC20Abi,
    functionName: 'balanceOf',
    args: [props.account],
    onSuccess(data) {
      setBalance(ethers.utils.formatEther(data));
    },
    onError(error) {
      console.log('Error', error);
    },
  })

  useContractRead({
    address: WETHContract,
    abi: ERC20Abi,
    functionName: 'allowance',
    args: [props.account, MariposaContract],
    onSuccess(data) {
      setAllowance(ethers.utils.formatEther(data));
    },
    onError(error) {
      console.log('Error', error);
    },
  })

  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'MRPSProject',
    onSuccess(data) {
      setProjectInfo(data);
      setMintPriceMB(data.mintPrice);
      setMintPrice(ethers.utils.formatEther(data.mintPrice));
      setProjectStep(data.step);
    },
    onError(error) {
      console.log('Error', error);
    },
  })

  useContractRead({
    address: MariposaContract,
    abi: MariposaAbi,
    functionName: 'senderhasMinted',
    args: [props.account],
    onSuccess(data) {
      setHasMinted(data);
    },
    onError(error) {
      console.log('Error', error);
    },
  })

  function WaitMintTransaction (props) {
    const { isError, isLoading } = useWaitForTransaction({
      hash: props.transaction,
      confirmations: 1,
      onError(error) {
        console.log('Error', error)
      },
      onSuccess(data) {
        step = 4;
      },
  
    })
    if (isLoading) return <div><img className="loading" src="/loading.gif" alt="loading" /></div>
    if (isError) return <div>Une erreur est survenue</div>
  
    return <>{ step === 4 && <div>Félicitations !<br />Mint réussi !</div> }</>;
  }
  
  function WaitApprovalTransaction (props) {
    const { isError, isLoading } = useWaitForTransaction({
      hash: props.transaction,
      confirmations: 1,
      onError(error) {
        console.log('Error', error)
      },
      onSuccess(data) {
        console.log('Success', data)
        step = 3;
      },
  
    })
    if (isLoading) return <div><img className="loading" src="/loading.gif" alt="loading" /></div>
    if (isError) return <div>Une erreur est survenue</div>
  
    return <div>{ step === 3 && <div><MintButton mintPrice={props.mintPrice} mintPriceMB={props.mintPriceMB} symbol={props.symbol} /></div> }</div>;
  }
    
  function MintButton () {
    const [message, setMessage] = useState('Vérifications en cours');

    const proof = getWLProof(address);
    
    const { config } = usePrepareContractWrite({
      address: MariposaContract,
      abi: MariposaAbi,
      functionName: 'publicmint',
      args: [proof],
      onError(error) {
        console.log('Error', error);
        setMessage('Mint impossible');
      },
      onSuccess() {
        setMessage('');
      }
    })
    const { data, isLoading, isSuccess, write } = useContractWrite(config);
  
    if (isLoading) return <div>Autorisez la transaction dans votre wallet</div>
  
    return (
      <div>
        { (!isSuccess) && message.length === 0 && <div><button disabled={!write} onClick={() => write?.()}>Mint NFT</button></div> }
        { message.length > 0 && <div>{message}</div> }
        { (projectInfo.step === 1 && proof.length === 0) && <div>Adresse absente de la White List</div> }
        { isSuccess && <div><WaitMintTransaction transaction={data.hash} /></div> }
      </div>
    );
  }
  
  function ApproveButton(props) {
    const { config } = usePrepareContractWrite({
      address: WETHContract,
      abi: ERC20Abi,
      functionName: 'approve',
      args: [MariposaContract, props.mintPriceMB],
      onError(error) {
        console.log('Error', error);
      }
    })
    const { data, isLoading, isSuccess, write } = useContractWrite(config);
  
    return (
      <div>
        { !isSuccess && !isLoading && <button disabled={!write} onClick={() => write?.()}>Approuver {props.mintPrice} {props.symbol}</button> }
        { isLoading && "Autorisez la transaction dans votre wallet" }
        { isSuccess && <div><WaitApprovalTransaction transaction={data.hash} mintPrice={props.mintPrice} mintPriceMB={props.mintPriceMB} symbol={props.symbol} /></div> }
      </div>
    );
  }
    
  if (projectStep < 1 || projectStep > 2) {
    return <div>Le mint n'est pas<br />possible actuellement</div>;
  }

  if (balance.length > 0) {
    if (parseFloat(balance) >= parseFloat(mintPrice)) { 
      if (parseFloat(mintPrice) > 0) {
        if (parseFloat(allowance)>=parseFloat(mintPrice)) {
          step = 3;
        } else {
          step = 2;
        }
      }
    } else {
      step = 1;
      lowBalance = true;
    }

    if (projectStep === 1 && hasMinted) return <div>Vous avez déjà<br />utilisé votre white list</div>

    return <>
      <div className="mintpriceinfos">
        <div className="projectstepinfo">
        { projectStep === 1 && "Phase en cours : Whitelists"}
        { projectStep === 2 && "Phase en cours : Public mint"}
        </div> 
        <div>Prix du mint : {mintPrice} {symbol}</div>
        { lowBalance === true ?
          <>
            <div>Balance : {balance} {symbol}</div>
            <div>Balance en {symbol} insuffisante</div>
          </> :
          <div>Balance en {symbol} : OK</div>
        }
      </div>
      <div className="actionbuttons">
        { step === 2 && <div><ApproveButton mintPrice={mintPrice} mintPriceMB={mintPriceMB} symbol={symbol} /></div> }
        { step === 3 && <div><MintButton mintPrice={mintPrice} mintPriceMB={mintPriceMB} symbol={symbol} /></div> }
      </div>
      </>;
  } else {
    return <div>Balance : ???.??? {symbol}</div>;
  }
}

function App() {
  const { address, isConnected } = useAccount()

  if (isConnected) {
      step=1;
  }

  return(
    <>
      <div className={ isConnected ? "ConnBtnDiv-connected" : "" }><Web3Button /></div>
      { step >= 1 && step <= 3 && isConnected && <FWETHBalance account={address} /> }
    </>
  );
}

export default App;
