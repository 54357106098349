import { Outlet, Link } from "react-router-dom";
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy';


const chains = [polygon];
//const chains = [polygonMumbai];
const projectId = process.env.REACT_APP_WCONNECT_ID;

const { provider } = configureChains(chains, [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY }), w3mProvider({ projectId }), publicProvider()])
//const { provider } = configureChains(chains, [w3mProvider({ projectId }), publicProvider()])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const targetChainId = parseInt(process.env.REACT_APP_CHAINID);
  
function SwitchNetworkButton() {
  const { switchNetwork } = useSwitchNetwork();

  return <button className="NetworkSwitch" onClick={() => switchNetwork?.(targetChainId)}>Revenir sur Polygon</button>;
}
  
const Layout = () => { 
  const { isConnected } = useAccount()
  const { chain } = useNetwork()

  /*
          <li>
            <Link to="/admin/collection">Collection</Link>
          </li>
          <li>
            <Link to="/admin/upload">Upload</Link>
          </li>
  */



  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to="/">Emergence</Link>
          </li>
          <li><a className="NavLink" href="https://calypso-nft.com/mariposa/" target="_blank">Le projet</a></li>
          <li></li>
          <li>
            <a className="NavLink" href="https://opensea.io/collection/mariposa-butterflies" target="_blank"><img alt="opensea" src="/opensea.png" /></a>
            <a className="NavLink" href="https://polygonscan.com/address/0x0A4F036548daD6c4F96C7B910892e749245d5498" target="_blank"><img alt="polygonscan" src="/polygon.png" /></a>
          </li>
        </ul>
      </nav>

      <div className="App">
        <header className="App-header">
          <WagmiConfig client={wagmiClient}>
          { isConnected && chain.id !== targetChainId ?
            <>
              <div>Mauvais réseau</div>
              <SwitchNetworkButton />
            </>
            :
            <Outlet />
          }
          </WagmiConfig>
        </header>
      </div>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode="dark"
        themeVariables={{
          '--w3m-font-family':'Megrim',
          '--w3m-accent-color': '#ffffff',
          '--w3m-accent-fill-color':'#000000',
          '--w3m-background-color': '#ffffff'
        }}

      />
    </>
  )
};

export default Layout;